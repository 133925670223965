<template>
  <div>
    <b-card>
      <progress-bar-status :progress="2" :status="aktivasiHB.status" />
    </b-card>
    <div class="bg-white p-5 rounded mt-3">
      <div
        class="d-flex flex-wrap align-items-start justify-content-between mb-3"
      >
        <h2 v-if="mode === 'registrasi'">Diisi Oleh HB</h2>
        <h2 v-if="mode === 'edit' || mode === 'detail'">
          Hasil Pemeriksaan Oleh HB
        </h2>
        <div>
          <b-button v-if="mode === 'detail'" @click="setMode('edit')">
            Edit
          </b-button>
          <b-button v-if="mode === 'edit'" variant="danger" @click="cancelEdit">
            Batal
          </b-button>
          <b-button
            v-if="mode === 'edit'"
            class="ml-2"
            variant="success"
            @click="saveChanges"
            :disabled="isBusy || !isFormValid"
          >
            <b-spinner small v-if="isBusy" />
            Simpan
          </b-button>
        </div>
      </div>
      <b-form @submit.prevent="saveChanges">
        <div class="d-flex flex-wrap" style="gap: 1rem">
          <b-form-group label="Nilai HB" class="w-50">
            <b-form-input
              v-model="aktivasiHB.hb_meter"
              :disabled="mode === 'detail'"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Golongan Darah">
            <b-form-select
              v-model="aktivasiHB.blood_type"
              :disabled="mode === 'detail'"
            >
              <b-form-select-option
                v-for="bloodType in bloodTypes"
                :key="bloodType.id"
                :value="bloodType.type"
              >
                {{ bloodType.type || bloodType.text }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group
            class="w-50"
            label="Alasan Penolakan"
            v-if="aktivasiHB.reason_rejected"
          >
            <b-form-input v-model="aktivasiHB.reason_rejected" disabled />
          </b-form-group>
        </div>
        <div class="d-flex justify-content-end">
          <b-button
            v-if="mode === 'registrasi'"
            class="ml-1"
            variant="primary"
            type="submit"
            :disabled="isBusy || !isFormValid"
          >
            <b-spinner small v-if="isBusy" />
            Simpan
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import ProgressBarStatus from "@/view/main/dashboards/registrationdonation/components/common/ProgressBarStatus.vue";
import manageHbAPI from "../../../../../../api/manageHB/manageHbAPI";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormRadioGroup,
  BFormRadio,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";

export default {
  name: "DetailHB",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormRadioGroup,
    BFormRadio,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BFormCheckbox,
    ProgressBarStatus,
    BSpinner,
  },
  data() {
    return {
      aktivasiHB: {
        hb_meter: "",
        blood_type: "",
        status: "",
        doctor_examinations_id: "",
      },

      mode: "detail",
      originalData: {},
      isBusy: false,

      bloodTypes: [
        { id: null, type: null, text: "Pilih Golongan Darah" },
        { id: null, type: "-" },
        { id: 1, type: "A Pos" },
        { id: 2, type: "A Neg" },
        { id: 3, type: "B Pos" },
        { id: 4, type: "B Neg" },
        { id: 5, type: "AB Pos" },
        { id: 6, type: "AB Neg" },
        { id: 7, type: "O Pos" },
        { id: 8, type: "O Neg" },
      ],
    };
  },
  computed: {
    isFormValid() {
      return this.aktivasiHB.hb_meter.length && this.aktivasiHB.blood_type;
    },
  },
  methods: {
    setInitialMode() {
      const route = this.$route.path;
      if (route.includes("/dashboards/hb-edit/")) {
        this.mode = "edit";
      } else if (route.includes("/dashboards/hb-registrasi/")) {
        this.mode = "registrasi";
      } else {
        this.mode = "detail";
      }
      console.log("Current mode:", this.mode);
    },
    async fetchDetailHB() {
      try {
        const { data: response } = await manageHbAPI.getDetail(
          this.$route.params.id
        );

        this.aktivasiHB.hb_meter = response.data.hb_meter;

        if (response.data.blood_type) {
          this.aktivasiHB.blood_type = this.mapBloodType(
            response.data.blood_type
          );
        } else {
          this.aktivasiHB.blood_type = "-";
        }

        this.aktivasiHB.status = response.data.status;
        this.aktivasiHB.reason_rejected = response.data.reason_rejected;
        this.aktivasiHB.doctor_examinations_id =
          response.data.doctor_examinations_id;

        this.updateEditableData();
      } catch (error) {
        console.error("Error fetching HB details:", error);
      }
    },
    mapBloodType(bloodType) {
      const mapping = {
        "A+": "A Pos",
        "A-": "A Neg",
        "B+": "B Pos",
        "B-": "B Neg",
        "AB+": "AB Pos",
        "AB-": "AB Neg",
        "O+": "O Pos",
        "O-": "O Neg",
      };

      if (!bloodType) {
        return "-";
      }

      return mapping[bloodType] || bloodType;
    },
    updateEditableData() {
      this.originalData = JSON.parse(JSON.stringify(this.aktivasiHB));
    },
    updateStatus() {
      const hb = parseFloat(this.aktivasiHB.hb_meter) || 0;
      if (hb < 12.4) {
        this.aktivasiHB.status = "Rejected";
        this.aktivasiHB.reason_rejected = "hb rendah";
      } else if (hb > 17) {
        this.aktivasiHB.status = "Rejected";
        this.aktivasiHB.reason_rejected = "hb tinggi";
      } else {
        this.aktivasiHB.status = "Approved";
        this.aktivasiHB.reason_rejected = "";
      }
    },
    setMode(newMode) {
      if (newMode === "edit") {
        this.originalData = JSON.parse(JSON.stringify(this.aktivasiHB));
      }
      this.mode = newMode;
    },
    async saveChanges() {
      this.isBusy = true;
      try {
        this.updateStatus();
        let selectedBlood = this.bloodTypes.find(
          (b) => b.type === this.aktivasiHB.blood_type
        );

        if (!selectedBlood) {
          selectedBlood = this.bloodTypes.find((b) => b.id === null);
        }

        let doctor_examinations_id;
        if (this.mode === "registrasi") {
          doctor_examinations_id = this.$route.params.id;
        } else {
          doctor_examinations_id = this.aktivasiHB.doctor_examinations_id;
        }

        const updatedData = {
          ...this.aktivasiHB,
          id: this.$route.params.id,
          doctor_examinations_id,
          blood_id: selectedBlood ? selectedBlood.id : null,
        };

        let response;
        if (this.mode === "registrasi") {
          response = await manageHbAPI.add(updatedData);
        } else {
          response = await manageHbAPI.edit(updatedData);
        }

        Object.assign(this.aktivasiHB, response.data.data);
        this.updateEditableData();

        this.$bvToast.toast("Perubahan berhasil disimpan", {
          title: "Sukses",
          variant: "success",
          solid: true,
        });
        if (this.mode === "registrasi") {
          this.$router.push({
            name: "HB",
          });
        } else {
          this.mode = "detail";
        }
      } catch (error) {
        console.error("Error saving changes:", error);
        this.$bvToast.toast("Gagal menyimpan perubahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isBusy = false;
      }
    },
    cancelEdit() {
      Object.assign(
        this.aktivasiHB,
        JSON.parse(JSON.stringify(this.originalData))
      );
      this.mode = "detail";
    },
  },
  mounted() {
    this.setInitialMode();
    if (this.mode !== "registrasi") {
      this.fetchDetailHB();
    }
  },
};
</script>
